
import Vue from "vue";

import { quoteMapState, quoteMapActions } from "@/store/modules/quote";
import ActivitiesView from "@/components/ActivitiesView/ActivitiesView.vue";
import QuotesActivitiesTimelineItem from "./QuotesActivitiesTimelineItem.vue";

interface IQuotesActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "quotesActivitiesView",
  components: {
    ActivitiesView,
    QuotesActivitiesTimelineItem
  },
  data(): IQuotesActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  mounted() {
    this.fetchQuote();
  },
  methods: {
    ...quoteMapActions(["getQuote"]),
    async fetchQuote(): Promise<void> {
      try {
        await this.getQuote(this.$route.params.quoteId);
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    queryOverride(): any {
      const query: any = {
        activityType: "QuoteActivity",
        "data.quoteId": this.$route.params.quoteId
      };
      return query;
    }
  }
});
